<template>
    <div :key="id">
        <el-input
            placeholder="权限搜索"
            v-model="AuthRouteSearch"
            clearable>
        </el-input>
        <div class="down-tree">
            <el-tree
                :data="data"
                show-checkbox
                node-key="id"
                ref="left_tree"
                :filter-node-method="filterNode"
                :default-expanded-keys="[]"
                :default-checked-keys="positionAuth"
                :props="defaultProps">
            </el-tree>
        </div>
    </div>
</template>

<script>

export default {
    name:'CommonAuthComponent',
    data(){
        return {
            AuthRouteSearch: '',
            data: [],
            positionAuth: [],
            defaultProps: {
                children: 'children',
                label: 'label'
            }
        }
    },
    computed:{},
    methods:{
        getAllAuth() {
            this.AllAuthRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        getHaveAuth() {
            this.HaveAuthRequest(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.positionAuth = s.result
                        this.$nextTick(() => {
                            this.$refs.left_tree.setCheckedKeys(this.positionAuth)
                        })
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        getCheckedKeys() {
            const arr = this.$refs.left_tree.getCheckedKeys().filter( v => v !== undefined)
            return arr
        },
        filterNode(value, data) {
            if (!value) {
                return true
            }
            return data.label.indexOf(value) !== -1;
        }
    },
    filters:{},
    props:{
        id: Number,
        AllAuthRequest: Function,
        HaveAuthRequest: Function
    },
    created(){
        this.getAllAuth()
        this.getHaveAuth()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{
        AuthRouteSearch(newVal) {
            this.$refs.left_tree.filter(newVal);
        },
        id(newVal) {
            this.id = newVal
            if(this.id) {
                this.getHaveAuth()
            }
        }
    }
}
</script>

<style lang='less'>
.down-tree{
    margin-top: 10px;
    height: 500px;
    display: block;
    overflow-y: scroll;
}
</style>